
/* GLOBAL */
body {
    font-family: 'Lato', sans-serif;
}
.wrapScroll {
    white-space: nowrap;
    overflow-x: auto;
  }
  
.noWrapScroll{
white-space: normal;
overflow-x: auto;
}

.objectFitCover {
    object-fit: cover;
}

.carousel-product-view {
    height: 50vh;
}

.cropText {
    width:cover;
    overflow:hidden;
    /* height:50px; */
    /* line-height:50px; */
    /* white-space: nowrap;  */
    /* Don't forget this one */
    text-overflow: ellipsis;
}​

/* EXPLORE */
.explore-card-image {
    height: 200px;
    object-fit: cover;
}
.explore-card-title {
    font-weight: bold;
}
.explore-card-price {
    font-weight: bolder;
    color: tomato;
    font-size: 2vh;
}


/* CART */
.cart-card-image {
    height: 120px;
    width: 120px;
    object-fit: cover;
}
.cart-card-title {
    font-weight: bold;
}
.cart-card-price {
    font-weight: bolder;
    color: tomato;
    font-size: 2vh;
}
.cart-card-input {
    border: none;
    width: 50px;
    color: tomato;
}


/* // X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap */

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 276px) { 
    .icon-mbl {
        font-size: 15px;
    }

    /* CART */
    .cart-card-image {
        height: 120px;
        width: 120px;
        object-fit: cover;
    }
    .cart-card-title {
        font-weight: bold;
    }
    .cart-card-price {
        font-weight: bolder;
        color: tomato;
        font-size: 2vh;
    }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .icon-mbl {
        font-size: 30px;
    }

    /* CART */
    .cart-card-image {
        height: 200px;
        width: auto;
        object-fit: cover;
    }
    .cart-card-title {
        font-weight: bold;
        font-size: 22px;
    }
    .cart-card-price {
        font-weight: bolder;
        color: tomato;
        font-size: 22px;
    }
 }

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .icon-mbl {
        font-size: 30px;
    }
 }

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .icon-mbl {
        font-size: 30px;
    }
 }

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { 
    .icon-mbl {
        font-size: 30px;
    }
 }

 